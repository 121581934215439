import React from "react"
import styled, { keyframes } from "styled-components"
import { rem } from "polished"
import Scrollbar from "smooth-scrollbar-react"
import throttle from "lodash/throttle"
import queryString from "query-string"

import SEO from "../components/seo"
import Header from "../components/header"
import TimeGrid from "../components/time/time-grid"
import InteractiveCounter from "../components/time/interactive-counter"
import CountDownTimer from "../components/time/countdown-timer"
import CountryRanks from "../components/time/country-ranks"
import TimeBar from "../components/time/time-bar"
import Share from "../components/time/share"
import Footer from "../components/time/footer"

import ageData from "../data/age-data.json"

import imgPattern from "../assets/images/pattern.jpg"

for (let i = 0; i < ageData.length; i++) {
  ageData[i].life_expectancy_avg = parseFloat(
    (
      (parseFloat(ageData[i].life_expectancy.male) +
        parseFloat(ageData[i].life_expectancy.female)) /
      2
    ).toFixed(2)
  )
}

for (let j = 0; j < ageData.length; j++) {
  ageData[j].retirement_age_avg = parseFloat(
    (
      (parseFloat(ageData[j].retirement_age.male) +
        parseFloat(ageData[j].retirement_age.female)) /
      2
    ).toFixed(2)
  )
}

const lifeExpectancyRanks = ageData
  .map(function(item) {
    return {
      country: item.country,
      life_expectancy_avg: item.life_expectancy_avg,
      numbers: item.life_expectancy,
    }
  })
  .sort(function(x, z) {
    return z.life_expectancy_avg - x.life_expectancy_avg
  })

const retirementAgeRanks = ageData
  .map(function(item) {
    return {
      country: item.country,
      retirement_age_avg: item.retirement_age_avg,
      numbers: item.retirement_age,
    }
  })
  .filter(item => item.retirement_age_avg > 0)
  .sort(function(x, z) {
    return z.retirement_age_avg - x.retirement_age_avg
  })

const animTimeGridWrap = keyframes`
  100% { opacity: 1; }
`

const animTimeBarWrap = keyframes`
  100% { opacity: 1; }
`

const Container = styled.div`
  max-height: 100vh;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  pointer-events: ${props => (props.scrollable ? `auto` : `none`)};

  .scroll-content {
    will-change: transform;
  }
`

const UserStats = styled.section`
  width: 90%;
  max-width: ${rem(1200)};
  margin: 0 auto ${rem(50)} auto;
  display: flex;
  align-items: bottom;

  @media ${props => props.theme.mediumDown} {
    flex-wrap: wrap;
    margin-bottom: ${rem(20)};
  }
`

const Stat = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${props => props.theme.colorGreyLightest};
  padding: ${rem(10)} ${rem(15)};
  margin: 0 ${rem(2)};
  border-radius: ${rem(4)};
  position: relative;
  overflow: hidden;
  transition: opacity 0.8s ease-out, transform 0.8s ease-in;
  opacity: ${props => (props.loaded ? 1 : 0)};
  transform: ${props =>
    props.loaded ? `translateY(0px)` : `translateY(10px)`};

  &:nth-child(1) {
    transition-delay: 0.2s;
  }
  &:nth-child(2) {
    transition-delay: 0.4s;
  }
  &:nth-child(3) {
    transition-delay: 0.6s;
  }
  &:nth-child(4) {
    transition-delay: 0.8s;
  }

  @media ${props => props.theme.mediumUp} {
    flex: 1;
  }

  @media ${props => props.theme.mediumDown} {
    flex: auto;
    width: calc(50% - ${rem(4)});
    margin: ${rem(2)};
  }

  @media ${props => props.theme.smallDown} {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  &::before {
    content: "";
    display: ${props => (props.barColor ? `block` : `none`)};
    background: ${props => props.barColor || `transparent`};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${rem(2)};
  }

  h2 {
    text-transform: uppercase;
    color: ${props => props.theme.colorGrey};
    display: flex;
    flex-direction: row;
    align-items: top;
    font-size: ${rem(12)};
    font-weight: 500;

    &::before {
      content: "";
      display: ${props => (props.barColor ? `block` : `none`)};
      vertical-align: middle;
      background: ${props => props.barColor || `transparent`};
      width: ${rem(10)};
      min-width: ${rem(10)};
      height: ${rem(10)};
      border-radius: 50%;
      margin-right: ${rem(8)};
      margin-top: ${rem(4)};
    }
  }

  p {
    font-size: ${rem(48)};
    font-weight: 300;
    color: ${props => props.theme.colorBlueDark};
    font-weight: 300;

    @media ${props => props.theme.mediumDown} {
      font-size: ${rem(36)};
    }

    @media ${props => props.theme.smallDown} {
      font-size: ${rem(18)};
      text-align: right;
      font-weight: 400;
    }
  }
`

const TimeGridWrap = styled.div`
  opacity: 0;
  animation: ${animTimeGridWrap} 1s 1s linear forwards;
`

const TimeBarWrap = styled.div`
  opacity: 0;
  animation: ${animTimeBarWrap} 1s 11s linear forwards;
`

const Error = styled.blockquote`
  margin-top: 20vh;
  text-align: center;
  width: 90%;
  max-width: ${rem(800)};
  margin-left: auto;
  margin-right: auto;

  p {
    font-size: ${rem(24)};
  }

  footer {
    font-size: ${rem(18)};
    font-style: italic;
    margin-top: ${rem(10)};
  }
`

const Button = styled.a`
  ${props => props.theme.shapeNotched()}

  width: 100%;
  max-width: ${rem(500)};
  margin: 0 auto;
  padding: 1.2em;
  display: block;
  font-size: ${rem(18)};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: ${props => props.theme.colorWhite};
  background-color: ${props => props.theme.colorBlueDark};
  background-image: url(${imgPattern});
  background-size: ${rem(90)};
  background-repeat: repeat;
  background-position: center center;
  transition: transform 0.2s ${props => props.theme.easingInOutSine};
  margin-top: ${rem(50)};

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    opacity: 0.8;
  }
`

const LifeStatsWrapper = styled.section`
  width: 90%;
  max-width: ${rem(2400)};
  margin: ${rem(100)} auto 0 auto;
  display: flex;
  align-items: bottom;

  @media ${props => props.theme.xxxxlargeDown} {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media ${props => props.theme.mediumDown} {
    margin: ${rem(40)} auto 0 auto;
  }
`

const ShareSection = styled.div`
  display: block;
  text-align: center;
  padding: ${rem(50)} 0;
  border-bottom: 1px solid ${props => props.theme.colorGreyLighter};

  a {
    cursor: pointer;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const RanksWrapper = styled.div`
  width: 90%;
  max-width: ${rem(2400)};
  margin: ${rem(20)} auto;
  display: flex;
  align-items: bottom;

  @media ${props => props.theme.xxxxlargeDown} {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

const CountDownWrapper = styled.section`
  width: 90%;
  max-width: ${rem(2400)};
  margin: ${rem(20)} auto 0 auto;
  display: flex;
  align-items: bottom;

  @media ${props => props.theme.xxxxlargeDown} {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

class TimePage extends React.Component {
  state = {
    country: null,
    scrollPosition: 0,
    timeBarPosition: 0,
    currentWeek: 0,
    loaded: false,
    scrollable: false,
    userName: ``,
    shareModalOpen: false,
  }

  COUNTRY = ``
  COUNTRY_DATA = ``
  USER_AGE = 0
  LIFE_EXPECTANCY = 0
  RETIREMENT_AGE = 0
  DOB = 0

  constructor() {
    super()

    if (typeof window !== `undefined`) {
      const params = queryString.parse(window.location.search)
      if (params) {
        const country = ageData.find(item => item.country === params.country)
        if (country) {
          let lifeExpectancy
          let retirementAge

          if (
            (country.retirement_age.male || country.retirement_age.female) ===
            `0`
          ) {
            country.retirement_age.male = Math.min(
              parseInt(country.life_expectancy.male),
              70
            )
            country.retirement_age.female = Math.min(
              parseInt(country.life_expectancy.female),
              70
            )
          }

          if (params.gender === `other`) {
            lifeExpectancy = Math.round(
              (parseInt(country.life_expectancy.male) +
                parseInt(country.life_expectancy.female)) /
                2
            )
            retirementAge = Math.round(
              (parseInt(country.retirement_age.male) +
                parseInt(country.retirement_age.female)) /
                2
            )
          } else {
            lifeExpectancy = country.life_expectancy[params.gender]
            retirementAge = country.retirement_age[params.gender]
          }

          this.COUNTRY = params.country
          this.COUNTRY_DATA = country
          this.LIFE_EXPECTANCY = parseInt(lifeExpectancy)
          this.RETIREMENT_AGE = parseInt(retirementAge)
          this.USER_AGE = parseInt(new Date().getFullYear() - params.dob)
          this.DOB = parseInt(params.dob)
        }

        if (params.name) this.userName = params.name
      }
    }
  }

  handleScroll = e => {
    this.setState({
      scrollPosition: e.offset.y,
    })
  }

  repositionTimeBar = () => {
    if (this.timegrid)
      this.setState({
        timeBarPosition:
          this.timegrid.getBoundingClientRect().top + this.state.scrollPosition,
      })
  }

  setCurrentWeek = currentWeek => {
    this.setState({ currentWeek })
  }

  openShareModal = () => {
    this.setState({ shareModalOpen: true })
  }

  closeShareModal = () => {
    this.setState({ shareModalOpen: false })
  }

  componentDidMount = () => {
    if (this.timegrid)
      this.setState({
        timeBarPosition: this.timegrid.getBoundingClientRect().top,
      })

    window.setTimeout(
      () =>
        this.setState({
          loaded: true,
        }),
      100
    )

    window.setTimeout(
      () =>
        this.setState({
          scrollable: true,
        }),
      11000
    )
  }

  render() {
    const {
      currentWeek,
      timeBarPosition,
      loaded,
      scrollable,
      scrollPosition,
      shareModalOpen,
    } = this.state

    const noError =
      this.USER_AGE &&
      this.LIFE_EXPECTANCY &&
      this.RETIREMENT_AGE &&
      this.USER_AGE >= 0

    const title = `${
      this.userName ? this.userName + `'s` : `Your`
    } Date of Birth`

    return (
      <React.Fragment>
        <SEO title={title} titleOverridePattern="[PAGE_TITLE]" />

        <Container scrollable={scrollable}>
          <Scrollbar
            damping={0.2}
            innerRef={node => (this.scrollbar = node)}
            onScroll={throttle(e => this.handleScroll(e))}
          >
            <Header animate={true} />

            {noError && (
              <UserStats>
                <Stat loaded={loaded}>
                  <h2>{title}</h2>

                  <p>{this.DOB}</p>
                </Stat>

                <Stat barColor={props => props.theme.colorBlue} loaded={loaded}>
                  <h2>{this.userName ? `${this.userName}'s` : `Your`} Age</h2>

                  <p>{this.USER_AGE}</p>
                </Stat>

                <Stat
                  barColor={props => props.theme.colorBlueLight}
                  loaded={loaded}
                >
                  <h2>Projected age of retirement</h2>

                  <p>{this.RETIREMENT_AGE}</p>
                </Stat>

                <Stat barColor={props => props.theme.colorAqua} loaded={loaded}>
                  <h2>Probable age of death</h2>

                  <p>{this.LIFE_EXPECTANCY}</p>
                </Stat>
              </UserStats>
            )}

            {noError ? (
              <TimeGridWrap ref={c => (this.timegrid = c)}>
                <TimeGrid
                  userName={this.userName}
                  currentScroll={scrollPosition}
                  setCurrentWeek={this.setCurrentWeek}
                  repositionTimeBar={this.repositionTimeBar}
                  userAge={this.USER_AGE}
                  lifeExpectancy={this.LIFE_EXPECTANCY}
                  retirementAge={this.RETIREMENT_AGE}
                />
              </TimeGridWrap>
            ) : (
              <Error>
                <p>
                  Memories vanish when we want to remember, but fix themselves
                  permanently in the mind when we want to forget.
                </p>

                <footer>— Emil Cioran</footer>

                <Button href="/">Try again</Button>
              </Error>
            )}

            {noError && (
              <LifeStatsWrapper>
                <InteractiveCounter
                  title="Time left until retirement"
                  number={this.RETIREMENT_AGE - this.USER_AGE}
                  scale="days"
                  loaded={currentWeek > 4600 ? true : false}
                />

                <InteractiveCounter
                  title="Time left until death"
                  number={this.LIFE_EXPECTANCY - this.USER_AGE}
                  scale="months"
                  loaded={currentWeek > 4600 ? true : false}
                />

                <InteractiveCounter
                  title={`Life expectancy for males in ${this.COUNTRY}`}
                  number={this.COUNTRY_DATA.life_expectancy.male}
                  scale="years"
                  loaded={currentWeek > 4600 ? true : false}
                />

                <InteractiveCounter
                  title={`Life expectancy for females in ${this.COUNTRY}`}
                  number={this.COUNTRY_DATA.life_expectancy.female}
                  scale="years"
                  loaded={currentWeek > 4600 ? true : false}
                />
              </LifeStatsWrapper>
            )}

            {noError && (
              <RanksWrapper>
                <CountryRanks
                  title={this.COUNTRY + `'s world rank in life expectancy`}
                  country={this.COUNTRY}
                  data={lifeExpectancyRanks}
                  loaded={currentWeek > 4600 ? true : false}
                />

                <CountryRanks
                  title={this.COUNTRY + `'s world rank in retirement age`}
                  country={this.COUNTRY}
                  data={retirementAgeRanks}
                  loaded={currentWeek > 4600 ? true : false}
                />
              </RanksWrapper>
            )}

            {noError && (
              <React.Fragment>
                <CountDownWrapper>
                  <CountDownTimer
                    title={`Sleep is the brother of Death. Productivity is the mother of Happiness. Tick, tock:`}
                    subtitle={`until ${
                      this.userName
                        ? `${this.userName} achieves`
                        : `you achieve`
                    } ultimate Happiness`}
                    year={this.DOB + this.LIFE_EXPECTANCY}
                    loaded={currentWeek > 4600 ? true : false}
                  />
                </CountDownWrapper>

                {noError && !this.userName && (
                  <ShareSection>
                    <Button onClick={this.openShareModal}>
                      Share your results
                    </Button>
                  </ShareSection>
                )}

                {noError && this.userName && (
                  <ShareSection>
                    <Button href="/">Get your own life stats</Button>
                  </ShareSection>
                )}

                <Footer />
              </React.Fragment>
            )}
          </Scrollbar>

          {noError && (
            <TimeBarWrap>
              <TimeBar
                userName={this.userName}
                currentWeek={currentWeek}
                userAge={this.USER_AGE}
                lifeExpectancy={this.LIFE_EXPECTANCY}
                retirementAge={this.RETIREMENT_AGE}
                timeBarPosition={timeBarPosition}
              />
            </TimeBarWrap>
          )}

          {noError && shareModalOpen && (
            <Share onCloseModal={this.closeShareModal} />
          )}
        </Container>
      </React.Fragment>
    )
  }
}

export default TimePage
