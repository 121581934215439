import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import CountryBar from "./country-bar"

const Rank = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${props => props.theme.colorGreyLightest};
  margin: 0 ${rem(10)};
  padding: ${rem(50)} ${rem(25)};
  border-radius: ${rem(4)};
  min-height: ${rem(600)};
  position: relative;
  overflow: hidden;
  transition: opacity 0.8s ease-out, transform 0.8s ease-in;
  opacity: ${props => (props.loaded ? 1 : 0)};
  transform: ${props =>
    props.loaded ? `translateY(0px)` : `translateY(10px)`};
  flex: 1;

  &:nth-child(1) {
    transition-delay: 1s;
  }

  &:nth-child(2) {
    transition-delay: 1.2s;
  }

  @media ${props => props.theme.xxxlargeDown} {
    flex: auto;
    width: calc(100% - ${rem(10)});
    margin: 0 ${rem(5)};
    margin-bottom: ${rem(10)};
    min-height: ${rem(300)};
  }

  @media ${props => props.theme.mediumDown} {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  h1 {
    color: ${props => props.theme.colorGrey};
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: center;
    text-align: center;
    font-size: ${rem(24)};
    font-weight: 600;

    @media ${props => props.theme.largeDown} {
      font-size: ${rem(20)};
    }

    @media ${props => props.theme.mediumDown} {
      font-size: ${rem(18)};
    }
  }

  p {
    font-size: ${rem(72)};
    font-weight: 300;
    color: ${props => props.theme.colorBlueDark};
    font-weight: 300;
    padding: 0 ${rem(25)};

    @media ${props => props.theme.mediumDown} {
      font-size: ${rem(48)};
    }

    @media ${props => props.theme.smallDown} {
      font-size: ${rem(36)};
      text-align: right;
      font-weight: 400;
    }
  }
`

const CountryList = styled.ul`
  list-style: none;
  padding-top: ${rem(40)};

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      margin-bottom: ${rem(10)};
    }

    & > div {
      flex: 1;
    }
  }
`

const Separator = styled.div`
  width: 95%;
  height: ${rem(1)};
  background: ${props => props.theme.colorWhite};
  margin: ${rem(15)} auto;
  position: relative;

  &::before,
  &::after {
    display: block;
    width: ${rem(15)};
    height: ${rem(15)};
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
  }

  &::before {
    content: "";
    background: ${props => props.theme.colorWhite};
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    content: "…";
    font-size: ${rem(14)};
    transform: translate(-50%, -95%);
    color: ${props => props.theme.colorGreyLight};
  }
`

class CountryRanks extends React.Component {
  render() {
    const firstThree = []
    const middleThree = []
    const lastThree = []
    let topOrBottom = false
    let currentCountryHasData = true
    let topCountriesCount = 3
    let bottomCountriesCount = 3

    const currentCountry = this.props.data.find(
      item => item.country === this.props.country
    )
    if (currentCountry) {
      const countryIndex = this.props.data.indexOf(currentCountry)

      if (countryIndex < topCountriesCount * 2) {
        topCountriesCount = topCountriesCount * 2
        topOrBottom = true
      }

      if (countryIndex > this.props.data.length - bottomCountriesCount * 2) {
        bottomCountriesCount = bottomCountriesCount * 2
        topOrBottom = true
      }

      if (!topOrBottom) {
        for (let i = countryIndex - 1; i < countryIndex + 2; i++) {
          const item = (
            <CountryBar
              key={i}
              country={this.props.data[i].country}
              data_male={this.props.data[i].numbers.male}
              data_female={this.props.data[i].numbers.female}
              rank={i + 1}
              current={
                this.props.country == this.props.data[i].country ? true : false
              }
            />
          )

          if (i >= 3 && i < this.props.data.length - 3) {
            middleThree.push(item)
          }
        }
      }
    } else {
      currentCountryHasData = false
    }

    for (let i = 0; i < topCountriesCount; i++) {
      const item = (
        <CountryBar
          key={i}
          country={this.props.data[i].country}
          data_male={this.props.data[i].numbers.male}
          data_female={this.props.data[i].numbers.female}
          rank={i + 1}
          current={
            this.props.country == this.props.data[i].country ? true : false
          }
        />
      )

      if (this.props.country == this.props.data[i].country) topOrBottom = true

      firstThree.push(item)
    }

    for (
      let i = this.props.data.length - bottomCountriesCount;
      i < this.props.data.length;
      i++
    ) {
      const item = (
        <CountryBar
          key={i}
          country={this.props.data[i].country}
          data_male={this.props.data[i].numbers.male}
          data_female={this.props.data[i].numbers.female}
          rank={i + 1}
          current={
            this.props.country == this.props.data[i].country ? true : false
          }
        />
      )

      if (this.props.country == this.props.data[i].country) topOrBottom = true

      lastThree.push(item)
    }

    return (
      <Rank loaded={this.props.loaded}>
        <h1>{this.props.title}</h1>

        <CountryList>
          <CountryBar
            key={0}
            header={true}
            country="Country"
            data_male="0"
            data_female="0"
            rank="0"
            current={false}
          />

          {firstThree}

          {currentCountryHasData && <Separator />}

          {!topOrBottom && middleThree}

          {!topOrBottom && <Separator />}

          {lastThree}
        </CountryList>
      </Rank>
    )
  }
}

CountryRanks.propTypes = {
  title: PropTypes.text,
  country: PropTypes.text,
  data: PropTypes.any,
  loaded: PropTypes.bool,
}

export default CountryRanks
