import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import Link from "../link"

import MosaicIcon from "../../assets/images/mosaic-logo.inline.svg"
import GogIcon from "../../assets/images/gog.inline.svg"
import SteamIcon from "../../assets/images/steam-icon.inline.svg"
import AppleIcon from "../../assets/images/apple-icon.inline.svg"

const Container = styled.footer`
  width: 100%;
`

const Info = styled.div`
  @media ${props => props.theme.smallUp} {
    padding: ${rem(160)} 0;
  }

  @media ${props => props.theme.smallDown} {
    padding: ${rem(80)} 0;
  }

  text-align: center;

  h2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: ${rem(969)};
    max-width: 90%;
    font-weight: 300;
    color: ${props => props.theme.colorBlueDark};
    letter-spacing: ${rem(-0.08)};
    line-height: 1.3;

    @media ${props => props.theme.smallUp} {
      font-size: ${rem(36)};
      margin-bottom: ${rem(74)};
    }

    @media ${props => props.theme.smallDown} {
      font-size: ${rem(25)};
      margin-bottom: ${rem(37)};
    }
  }

  svg {
    @media ${props => props.theme.smallUp} {
      width: ${rem(46)};
      height: ${rem(52)};
    }

    @media ${props => props.theme.smallDown} {
      width: ${rem(30)};
      height: ${rem(40)};
    }
  }
`

const Buttons = styled.div`
  border-top: 1px solid #c8c8c8;
  text-align: center;

  @media ${props => props.theme.smallUp} {
    padding: ${rem(50)} 0;
  }

  @media ${props => props.theme.smallDown} {
    padding: ${rem(30)} 5%;
  }

  a {
    display: inline-block;
    vertical-align: top;
    color: ${props => props.theme.colorBlueDark};
    letter-spacing: ${rem(-0.03)};
    padding-left: ${rem(35)};
    position: relative;

    @media ${props => props.theme.smallUp} {
      display: inline-block;
    }

    @media ${props => props.theme.smallDown} {
      display: block;
      text-align: left;
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }

    &:not(:last-of-type) {
      @media ${props => props.theme.smallUp} {
        margin-right: ${rem(30)};
      }

      @media ${props => props.theme.smallDown} {
        margin-bottom: ${rem(15)};
      }
    }
  }

  svg {
    width: ${rem(24)};
    height: ${rem(24)};
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`

const CtaFooter = () => (
  <Container>
    <Info>
      <h2>
        Life is too short for vacations. Time is too fleeting for thought. You
        are a cog in the machine. Everything is as it should be.
      </h2>

      <MosaicIcon aria-hidden="true" />
    </Info>

    <Buttons>
      <Link to="https://www.mosaiccorp.biz/" target="_blank">
        <MosaicIcon aria-hidden="true" /> Mosaic Corp
      </Link>

      <Link
        to="https://apps.apple.com/us/app/the-mosaic/id1454782852?ign-itsct=themosaic&ign-itscg=30800"
        target="_blank"
      >
        <AppleIcon aria-hidden="true" /> Apple Arcade
      </Link>

      <Link
        to="https://store.steampowered.com/app/349270/Mosaic/"
        target="_blank"
      >
        <SteamIcon aria-hidden="true" /> Steam
      </Link>

      <Link to="https://www.gog.com/game/mosaic" target="_blank">
        <GogIcon aria-hidden="true" /> Gog
      </Link>
    </Buttons>
  </Container>
)

export default CtaFooter
