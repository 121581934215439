import React from "react"
import ReactModal from "react-modal"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import debounce from "lodash/debounce"
import { rem } from "polished"

import colors from "../../theme/sections/colors"
import zIndexes from "../../theme/sections/z-index"

import imgPattern from "../../assets/images/pattern.jpg"

const animContainer = keyframes`
  0%   { opacity: 0; }
  100% { opacity: 1; }
`

const customStyles = {
  // http://reactcommunity.org/react-modal/styles/
  overlay: {
    position: `fixed`,
    zIndex: zIndexes.ziModal,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: `transparent`,
  },
  content: {
    padding: 0,
    position: `static`,
    overflow: `visible`,
    WebkitOverflowScrolling: `auto`,
    outline: `none`,
    borderRadius: 0,
    border: `none`,
    background: `transparent`,
    color: colors.colorWhite,
  },
}

const Title = styled.h1`
  font-size: ${rem(48)};
  font-weight: 300;
  text-align: center;

  @media ${props => props.theme.xlargeDown} {
    font-size: ${rem(36)};
  }

  @media ${props => props.theme.mediumDown} {
    font-size: ${rem(24)};
  }
`

const Text = styled.div`
  font-size: ${rem(24)};
  text-align: center;
  margin: ${rem(8)} 0 ${rem(16)} 0;

  @media ${props => props.theme.mediumDown} {
    font-size: ${rem(18)};
  }
`

const Step = styled.div`
  width: 80%;
  max-width: ${rem(1200)};
  margin: ${rem(80)} auto 0 auto;
  padding-top: ${rem(40)};
  position: relative;

  @media ${props => props.theme.mediumDown} {
    padding-top: ${rem(20)};
    margin-top: ${rem(40)};
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 25%;
    height: 1px;
    transform: translateX(-50%);
    background: ${props => props.theme.colorBlueLight};

    @media ${props => props.theme.mediumDown} {
      width: 50%;
    }
  }
`

const Number = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 2em;
  height: 2em;
  line-height: 2em;
  font-size: ${rem(24)};
  font-weight: 300;
  color: ${props => props.theme.colorBlueLight};
  text-align: center;
  margin: 0 auto ${rem(20)} auto;
  transform: translate(-50%, -50%);

  @media ${props => props.theme.mediumDown} {
    font-size: ${rem(16)};
    font-weight: 400;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 2em;
    height: 2em;
    transform: translateX(-50%) rotate(45deg);
    border: 1px solid ${props => props.theme.colorBlueLight};
    background: ${props => props.theme.colorBlueDark};
    z-index: 0;
  }

  span {
    position: relative;
    z-index: 1;
  }
`

const ContainerOuter = styled.div`
  height: 100vh;
  padding: ${rem(80)};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  text-align: center;
  color: ${props => props.theme.colorWhite};
  background-image: linear-gradient(
      120deg,
      rgba(8, 27, 51, 0.9) 30%,
      rgba(8, 27, 51, 0) 100%
    ),
    url(${imgPattern});
  background-size: auto, ${rem(90)};
  background-repeat: repeat;
  background-position: center center;
  animation: ${animContainer} 0.5s linear;

  @media ${props => props.theme.largeDown} {
    padding: ${rem(40)};
  }

  @media ${props => props.theme.mediumDown} {
    padding: ${rem(20)};
  }

  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
`

const Container = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: middle;

  input[type="text"] {
    width: 100%;
    font-size: ${rem(36)};
    margin-top: ${rem(40)};
    margin-bottom: ${rem(40)};
    display: block;
    padding: 0.5em 1em;
    color: ${props => props.theme.colorBlueDark};
    background: ${props => props.theme.colorWhite};
    border: 1px solid ${props => props.theme.colorBlueLight};
    border-radius: ${rem(8)};

    @media ${props => props.theme.mediumDown} {
      font-size: ${rem(18)};
    }
  }

  input[type="url"] {
    width: 100%;
    font-size: ${rem(36)};
    display: block;
    padding: 0.5em 1em;
    margin-top: ${rem(40)};
    color: ${props => props.theme.colorWhite};
    border: 1px solid ${props => props.theme.colorBlueLight};
    border-radius: ${rem(8)};
    text-align: center;

    @media ${props => props.theme.mediumDown} {
      font-size: ${rem(18)};
    }
  }
`

const CloseButton = styled.button.attrs({ type: `button` })`
  position: absolute;
  top: 2vw;
  right: 2vw;
  width: ${rem(30)};
  height: ${rem(30)};
  color: ${props => props.theme.colorBlueDark};

  @media ${props => props.theme.mediumDown} {
    top: ${rem(20)};
    right: ${rem(20)};
  }

  &::before,
  &::after {
    content: "";
    display: block;
    width: ${rem(2)};
    height: ${rem(30)};
    background: ${props => props.theme.colorWhite};
    position: absolute;
    top: 0;
    right: 50%;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`

const SuccessMessage = styled.div`
  position: fixed;
  bottom: 2em;
  padding: 0.6em 1em;
  left: 50%;
  color: ${props => props.theme.colorBlueDark};
  background-color: ${props => props.theme.colorGreen};
  border-radius: 0.4em;
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translate(-50%, ${props => (props.visible ? `0` : `50%`)});
  transition: transform 0.5s ease, opacity 0.5s ease;
`

class Share extends React.Component {
  state = {
    userName: undefined,
    copiedToClipboard: false,
  }

  componentDidMount() {
    ReactModal.setAppElement(`#___gatsby`)
  }

  modalAfterOpen = () => {
    this.inputEl.focus()
  }

  inputChange = () => {
    const { value } = this.inputEl
    this.setState({ userName: value })
  }

  selectText = e => {
    e.target.select()
    document.execCommand(`copy`)
    this.setState({ copiedToClipboard: true })
    window.setTimeout(() => this.setState({ copiedToClipboard: false }), 5000)
  }

  render() {
    const { userName } = this.state

    return (
      <ReactModal
        style={customStyles}
        onAfterOpen={this.modalAfterOpen}
        isOpen={true}
      >
        <ContainerOuter>
          <Container>
            <CloseButton onClick={this.props.onCloseModal}>Close</CloseButton>

            <Title>Misery loves company.</Title>

            <Step>
              <Text>
                <Number>
                  <span>1</span>
                </Number>
                What sequence of letters do people identify you by?
              </Text>

              <input
                type="text"
                placeholder="Your name"
                onChange={debounce(() => this.inputChange(), 1000)}
                ref={el => (this.inputEl = el)}
              />
            </Step>

            {userName && (
              <Step>
                <Number>
                  <span>2</span>
                </Number>

                <Text>
                  ...copy the URL below and share your path to happiness:
                </Text>

                <input
                  type="url"
                  placeholder="Enter your name&hellip;"
                  onFocus={this.selectText}
                  value={`${window.location.href}&name=${encodeURI(userName)}`}
                />

                <SuccessMessage visible={this.state.copiedToClipboard}>
                  URL copied to clipboard
                </SuccessMessage>
              </Step>
            )}
          </Container>
        </ContainerOuter>
      </ReactModal>
    )
  }
}

Share.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
}

export default Share
