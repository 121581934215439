import React from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import { rem } from "polished"

import Skull from "../../assets/images/skull.inline.svg"
import AlarmClock from "../../assets/images/alarmclock.inline.svg"
import Pill from "../../assets/images/pill.inline.svg"

const flickerAnimation = keyframes`
  0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
    opacity: 1;
  }

  20%, 24% {
    opacity: 0.7;
  }

  55% {
    opacity: 0.5;
  }
`

const HorizontalBar = styled.div`
  position: fixed;
  right: 0;
  width: 100%;
  height: ${rem(2)};
  z-index: 99;
  transition: opacity 0.4s ease;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 0 0.5rem ${props => props.theme.colorBlue},
      inset 0 0 0.5rem ${props => props.theme.colorBlue},
      0 0 2rem ${props => props.theme.colorBlueLight},
      inset 0 0 2rem ${props => props.theme.colorBlueLight},
      0 0 4rem ${props => props.theme.colorBlueLight},
      inset 0 0 4rem ${props => props.theme.colorBlueLight};
    animation: ${flickerAnimation} 1.5s infinite alternate;
  }

  &.hidden {
    opacity: 0;
  }
`

const MessageBubble = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  background: ${props => props.theme.colorBlueDark};
  color: ${props => props.theme.colorWhite};
  height: ${rem(70)};
  padding: 0 ${rem(60)} 0 ${rem(50)};
  display: flex;
  align-items: center;
  clip-path: polygon(
    0 50%,
    35px 0,
    calc(100% - 35px) 0,
    100% 50%,
    calc(100% - 35px) 100%,
    35px 100%,
    0 50%
  );
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: translate(-50%, -50%) perspective(1200px) rotateX(0deg);
  transform-origin: 50% 50%;
  transition: transform 1s ease;

  &.hidden {
    transform: translate(-50%, -50%) perspective(1200px) rotateX(180deg);
  }

  &.--intro {
    color: ${props => props.theme.colorWhite};
    background: ${props => props.theme.colorBlue};
    text-align: center;

    h1::before {
      display: none;
    }

    @media ${props => props.theme.mediumDown} {
      & > div {
        margin: 0 auto;
      }
    }
  }

  &.--gone {
    h1::before {
      background: ${props => props.theme.colorBlue};
    }
  }

  &.--retirement {
    h1::before {
      background: ${props => props.theme.colorBlueLight};
    }
  }

  &.--life {
    h1::before {
      background: ${props => props.theme.colorAqua};
    }
  }

  @media ${props => props.theme.mediumDown} {
    position: fixed;
    top: auto;
    bottom: 0;
    width: 100%;
    height: auto;
    padding: ${rem(20)};
    transform: translate(-50%, 0);
    transform-origin: 50% 100%;
    clip-path: none;

    &.hidden {
      transform: rotateX(90deg) translate(-50%, 100%);
    }
  }

  svg {
    stroke: currentColor;
    margin-right: ${rem(20)};
  }

  h1 {
    font-weight: 600;
    font-size: ${rem(16)};

    &::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: ${rem(10)};
      min-width: ${rem(10)};
      height: ${rem(10)};
      border-radius: 50%;
      margin-top: ${rem(-2)};
      margin-right: ${rem(5)};
      transition: background 0.5s ease;
    }

    @media ${props => props.theme.mediumDown} {
      font-size: ${rem(14)};
    }
  }

  p {
    font-weight: 400;
    font-size: ${rem(16)};

    @media ${props => props.theme.mediumDown} {
      font-size: ${rem(14)};
    }
  }
`

const YearCounter = styled.div`
  position: absolute;
  right: ${rem(30)};
  top: 50%;
  width: ${rem(44)};
  height: ${rem(44)};
  text-align: center;
  line-height: ${rem(44)};
  color: ${props => props.theme.colorWhite};
  font-weight: bold;
  transform: translateY(-50%);
  transition: color 0.2s ease-out;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: ${props => props.theme.colorBlue};
    border: 2px solid ${props => props.theme.colorWhite};
    transform: rotate(45deg);
    z-index: -1;
    transition: background 0.2s ease-out,
      transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55),
      border-radius 0.2s ease-in;
  }

  &.--retirement {
    &::before {
      background: ${props => props.theme.colorBlueLight};
      transform: rotate(135deg);
    }
  }

  &.--life {
    &::before {
      background: ${props => props.theme.colorAqua};
      transform: rotate(225deg);
    }
  }

  &.--death {
    &::before {
      background: ${props => props.theme.colorGreyLighter};
      transform: rotate(315deg);
      border-radius: 50%;
    }
  }

  @media ${props => props.theme.mediumDown} {
    width: ${rem(30)};
    height: ${rem(30)};
    line-height: ${rem(30)};
    font-size: ${rem(12)};
  }

  @media ${props => props.theme.largeDown} {
    right: ${rem(10)};
  }
`

class TimeBar extends React.Component {
  step = `intro`
  title = false
  message = false
  tooltip = false
  scrolledOnce = false
  counterClass = ``

  componentDidMount = () => {}

  componentDidUpdate = () => {
    if (this.props.currentWeek < Math.round((this.props.userAge / 10) * 52)) {
      this.step = `intro`
    } else if (this.props.currentWeek < this.props.userAge * 52) {
      this.step = `gone`
    } else if (this.props.currentWeek < this.props.retirementAge * 52) {
      this.step = `retirement`
    } else if (this.props.currentWeek < this.props.lifeExpectancy * 52) {
      this.step = `life`
    } else {
      this.step = `death`
    }
  }

  render() {
    return (
      <HorizontalBar
        className={this.props.currentWeek / 52 < 90 ? `visible` : `hidden`}
        ref={this.HorizontalBar}
        style={{ top: this.props.timeBarPosition }}
      >
        <MessageBubble
          className={
            (this.step === `intro` ? `visible` : `hidden`) + ` --intro`
          }
        >
          <div>
            <h1>Time is fleeting. Happiness is elusive.</h1>
            <p>
              Scroll down to see how long{` `}
              {this.props.userName ? this.props.userName + ` has` : `you have`}
              {` `}
              left
            </p>
          </div>
        </MessageBubble>

        <MessageBubble
          className={(this.step === `gone` ? `visible` : `hidden`) + ` --gone`}
        >
          <Skull className="skull" />
          <div>
            <h1>Dead and gone</h1>
            <p>{this.props.currentWeek} weeks wasted being unhappy</p>
          </div>
        </MessageBubble>

        <MessageBubble
          className={
            (this.step === `retirement` ? `visible` : `hidden`) +
            ` --retirement`
          }
        >
          <AlarmClock className="alarm-clock" />
          <div>
            <h1>Time left to accomplish happiness</h1>
            <p>
              {Math.max(
                0,
                Math.round(
                  (this.props.retirementAge * 52 - this.props.currentWeek) * 7
                )
              )}
              {` `}
              days left to do something meaningful
            </p>
          </div>
        </MessageBubble>

        <MessageBubble
          className={(this.step === `life` ? `visible` : `hidden`) + ` --life`}
        >
          <Pill className="pill" />
          <div>
            <h1>Time spent fighting off disease and regrets</h1>
            <p>Some pills are harder to swallow than others</p>
          </div>
        </MessageBubble>

        <YearCounter className={`--` + this.step}>
          {Math.min(Math.round(this.props.currentWeek / 52), 90)}
        </YearCounter>
      </HorizontalBar>
    )
  }
}

TimeBar.propTypes = {
  currentWeek: PropTypes.number,
  userName: PropTypes.text,
  userAge: PropTypes.number,
  lifeExpectancy: PropTypes.number,
  retirementAge: PropTypes.number,
  timeBarPosition: PropTypes.number,
}

export default TimeBar
