import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

const Country = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    margin-bottom: ${rem(10)};
  }

  & > div {
    flex: 1;
  }
`

const CountryName = styled.div`
  font-size: ${rem(16)};
  line-height: ${rem(32)};
  max-width: ${rem(200)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: ${props => (props.current ? `600` : `400`)};

  @media ${props => props.theme.xxlargeDown} {
    font-size: ${rem(14)};
  }

  @media ${props => props.theme.mediumDown} {
    min-width: 50%;
    flex: 1;
  }

  small {
    font-size: ${rem(12)};
    text-transform: uppercase;
    color: ${props => props.theme.colorGrey};
  }

  span {
    display: inline-block;
    vertical-align: middle;
    opacity: 0.8;
    font-size: ${rem(10)};
    line-height: ${rem(12)};
    margin-right: ${rem(2)};
    margin-top: ${rem(-4)};
    border-bottom: 1px solid currentColor;

    @media ${props => props.theme.xxlargeDown} {
      margin-top: ${rem(-2)};
    }
  }
`

const Bar = styled.div`
  flex: 1;
  height: ${rem(12)};
  font-size: ${rem(12)};
  padding: 0 ${rem(25)};
  text-transform: uppercase;
  text-align: ${props => props.align};
  color: ${props => props.theme.colorGrey};
  display: flex;
  justify-content: ${props =>
    props.align == `right` ? `flex-end` : `flex-start`};
  align-items: center;

  @media ${props => props.theme.mediumDown} {
    padding: 0;
    justify-content: ${props =>
      props.align == `right` ? `flex-start` : `flex-end`};
  }

  div {
    height: 100%;
    background: ${props =>
      props.current ? props.theme.colorBlueDark : props.theme.colorGreyLighter};
    margin-left: ${props => (props.align == `right` ? `5px` : 0)};
    margin-right: ${props => (props.align == `left` ? `5px` : 0)};

    @media ${props => props.theme.mediumDown} {
      display: none;
    }

    clip-path: polygon(
      0 6px,
      6px 0,
      calc(100% - 6px) 0,
      100% 6px,
      calc(100% - 6px) 12px,
      6px 12px,
      0 6px
    );
  }
`

class CountryBar extends React.Component {
  render() {
    if (this.props.header) {
      return (
        <li>
          <Bar percentage={0} align="right">
            Males
          </Bar>

          <CountryName>
            <small>Country</small>
          </CountryName>

          <Bar percentage={0} align="left">
            Females
          </Bar>
        </li>
      )
    } else {
      return (
        <Country>
          <Bar align="right" current={this.props.current}>
            {this.props.data_male}
            <div style={{ width: this.props.data_male + `%` }} />
          </Bar>

          <CountryName current={this.props.current}>
            <span>{this.props.rank}</span> {this.props.country}
          </CountryName>

          <Bar align="left" current={this.props.current}>
            <div style={{ width: this.props.data_female + `%` }} />
            {this.props.data_female}
          </Bar>
        </Country>
      )
    }
  }
}

CountryBar.propTypes = {
  header: PropTypes.bool,
  country: PropTypes.text,
  data_male: PropTypes.text,
  data_female: PropTypes.text,
  rank: PropTypes.text,
  current: PropTypes.bool,
}

export default CountryBar
