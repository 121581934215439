import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import moment from "moment"
import "moment-countdown"

import imgPattern from "../../assets/images/pattern.jpg"

const StatWrapper = styled.div`
  width: 100%;
`

const Stat = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.colorBlueDark};
  background-image: url(${imgPattern});
  background-size: ${rem(90)};
  background-repeat: repeat;
  background-position: center center;
  margin: 0 ${rem(10)};
  padding: ${rem(100)} ${rem(25)};
  border-radius: ${rem(4)};
  position: relative;
  overflow: hidden;
  transition: opacity 0.8s ease-out, transform 0.8s ease-in;
  opacity: ${props => (props.loaded ? 1 : 0)};
  transform: ${props =>
    props.loaded ? `translateY(0px)` : `translateY(10px)`};
  flex: 1;
  text-align: center;

  &:nth-child(1) {
    transition-delay: 1.4s;
  }

  @media ${props => props.theme.mediumDown} {
    padding: ${rem(50)} ${rem(25)};
    margin: 0;
  }

  h1,
  h2 {
    color: ${props => props.theme.colorBlueLight};
    font-size: ${rem(36)};

    @media ${props => props.theme.xxxxlargeDown} {
      font-size: ${rem(24)};
    }

    @media ${props => props.theme.mediumDown} {
      font-size: ${rem(18)};
    }
  }

  h1 {
    margin-bottom: ${rem(24)};
  }

  h2 {
    margin-top: ${rem(24)};
  }

  p {
    font-size: ${rem(72)};
    line-height: 1.2;
    font-weight: 300;
    color: ${props => props.theme.colorWhite};
    font-weight: 300;

    @media ${props => props.theme.xxxxlargeDown} {
      font-size: ${rem(36)};
      font-weight: 400;
    }

    @media ${props => props.theme.mediumDown} {
      font-size: ${rem(24)};
      font-weight: 400;
    }
  }
`

class CountDownTimer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      countdown: undefined,
    }
  }

  componentDidMount = () => {
    window.setInterval(() => {
      const timeTillDate = `01 01 ` + this.props.year
      const timeFormat = `MM DD YYYY`
      const then = moment(timeTillDate, timeFormat)
      const now = moment()
      const countdown = moment(then)
        .countdown(now)
        .toString()

      this.setState({ countdown })
    }, 1000)
  }

  render() {
    const { countdown } = this.state

    return (
      <StatWrapper>
        {this.props.year > new Date().getFullYear() ? (
          <Stat loaded={this.props.loaded}>
            <h1>{this.props.title}</h1>
            <p>{countdown}</p>
            <h2>{this.props.subtitle}</h2>
          </Stat>
        ) : (
          <Stat loaded={this.props.loaded}>
            <p>
              Memories vanish when we want to remember, but fix themselves
              permanently in the mind when we want to forget.
            </p>
            <h2>— Emil Cioran</h2>
          </Stat>
        )}
      </StatWrapper>
    )
  }
}

CountDownTimer.propTypes = {
  year: PropTypes.number,
  title: PropTypes.text,
  subtitle: PropTypes.text,
  loaded: PropTypes.bool,
}

export default CountDownTimer
