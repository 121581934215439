import React from "react"
import Two from "two.js"
import PropTypes from "prop-types"
import { Howl } from "howler"
import throttle from "lodash/throttle"
import styled, { keyframes } from "styled-components"
import { rem } from "polished"

import TimeScale from "./time-scale"

const GridWrapper = styled.section`
  width: 100%;
  position: relative;
  margin-top: 50px;
`

const WeeksGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const IntroScene = styled.section`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translateX(-50%);
`

const IntroSquare = styled.div`
  position: absolute;
  width: ${rem(100)};
  height: ${rem(100)};
  background: ${props => props.theme.colorBlueDark};
  transition: opacity 0.5s ease-in, transform 2s ease, width 2s ease-out,
    height 2s ease-out;
  transform: translate(650px, 150px) rotate(45deg);
`

const introTextAnimation = keyframes`
  0%, 20% { opacity: 0; transform: translateY(25px); }
  30%, 90% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-25px); }
`

const IntroText = styled.h1`
  position: absolute;
  width: 100%;
  top: ${rem(50)};
  left: 0;
  text-align: center;
  animation: ${introTextAnimation} 8s ${props => props.theme.easingInSine};
  opacity: 0;
`

Number.prototype.map = function(inMin, inMax, outMin, outMax) { // eslint-disable-line
  const result = ((this - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin

  if (result > outMax) return outMax
  else if (result < outMin) return outMin
  else return result
}

class TimeGrid extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      introPlayed: false,
      introSquareStyles: {
        opacity: 0,
        width: `100px`,
        height: `100px`,
        transform:
          `translate(` + parseInt(1400 / 2 - 50) + `px, 150px) rotate(45deg)`,
      },
      introSceneWidth: 1400,
    }

    this.WEEK_SIZE = 18
    this.WEEK_SPACING = 4
    this.WEEK_OUTER_SIZE = this.WEEK_SIZE + this.WEEK_SPACING
    this.YEARS = 90
    this.MAX_WIDTH = 2400
    this.graphWidth = this.MAX_WIDTH
    this.currentWeek = 0
    this.weeks = []

    this.two = null

    this.intro = true

    this.passedAge = false
    this.passedRetirement = false
    this.blip = false
    this.passedDeath = false
    this.thud = false
    this.flatline = false
  }

  setUpScene = () => {
    this.createWeeks()

    this.setState({
      introSceneWidth: this.graphWidth,
    })

    window.setTimeout(() => {
      this.setState({
        introSquareStyles: {
          opacity: 1,
          transform:
            `translate(` +
            parseInt(this.state.introSceneWidth / 2 - 50) +
            `px, 150px) rotate(315deg)`,
        },
        introSceneWidth: this.graphWidth,
      })
    }, 1000)

    window.setTimeout(() => {
      this.setState({
        introSquareStyles: {
          transform:
            `translate(` +
            parseInt(this.weeks[0].translation._x - this.weeks[0].width / 2) +
            `px, ` +
            parseInt(this.weeks[0].translation._y - this.weeks[0].height / 2) +
            `px)`,
          width: this.WEEK_SIZE + `px`,
          height: this.WEEK_SIZE + `px`,
        },
        introSceneWidth: this.graphWidth,
      })
    }, 8000)

    window.setTimeout(() => {
      // Using a proxy object here, to prevent having to update
      // attributes that aren't supposed to change
      const introSquareStylesProxy = { ...this.state.introSquareStyles }
      introSquareStylesProxy.opacity = 0
      this.setState({ introSquareStyles: introSquareStylesProxy })

      this.loadWeeksIn()
      window.addEventListener(
        `resize`,
        throttle(() => {
          this.handleResize()
        })
      )

      this.setState({
        introPlayed: true,
      })
    }, 11000)
  }

  createWeeks = () => {
    const initialX =
      (this.graphWidth % this.WEEK_OUTER_SIZE) / 2 + this.WEEK_OUTER_SIZE * 2
    let xAxis = initialX
    let yAxis = this.WEEK_OUTER_SIZE / 2

    for (let i = 0; i < this.YEARS * 52; i++) {
      const square = this.two.makeRectangle(
        xAxis,
        yAxis,
        this.WEEK_SIZE,
        this.WEEK_SIZE
      )
      xAxis += this.WEEK_OUTER_SIZE

      if (xAxis > this.graphWidth - this.WEEK_OUTER_SIZE * 2) {
        xAxis = initialX
        yAxis += this.WEEK_OUTER_SIZE
      }
      square.scale = 0
      square.fill = `#081B33`
      square.noStroke()
      this.weeks.push(square)
    }

    const canvasHeight = this.weeks[
      this.weeks.length - 1
    ].getBoundingClientRect().top
    this.two.height = canvasHeight + this.WEEK_OUTER_SIZE / 2
  }

  loadWeeksIn = () => {
    this.weeks.forEach((week, index) => {
      if (index < this.props.lifeExpectancy * 52) week.scale = 0.2
      else week.scale = 0.1
      week.rotation = 45 * (Math.PI / 180)
    })

    // this.weeks[this.props.userAge*52].translation.x,
    // this.weeks[this.props.userAge*52].translation.y - 6

    this.reFlowWeeks()
  }

  reFlowWeeks = () => {
    const adaptedScroll = this.props.currentScroll
    const firstSquare = this.weeks[0].getBoundingClientRect().top
    const lastSquare = this.weeks[this.weeks.length - 1].getBoundingClientRect()
      .top
    this.currentWeek = Math.floor(
      adaptedScroll.map(firstSquare, lastSquare, 1, this.weeks.length)
    )

    this.weeks.forEach((week, index) => {
      if (
        index < this.props.lifeExpectancy * 52 &&
        index < this.currentWeek + this.graphWidth &&
        index > this.currentWeek - this.graphWidth
      ) {
        const squareScale = this.currentWeek.map(index - 600, index, 0.2, 1)
        const squareRotation = this.currentWeek.map(
          index - 600,
          index - 10,
          45,
          180
        )

        if (index < this.props.userAge * 52 && index < this.currentWeek)
          week.fill = `#5A92A8`
        else if (
          index < this.props.retirementAge * 52 &&
          index < this.currentWeek
        )
          week.fill = `#9CD8EA`
        else if (
          index < this.props.lifeExpectancy * 52 &&
          index < this.currentWeek
        )
          week.fill = `#50B4B4`
        else week.fill = `#081B33`

        week.scale = squareScale
        week.rotation = squareRotation * (Math.PI / 180)
      }
    })

    if (this.currentWeek <= this.props.lifeExpectancy * 52) {
      this.blip.rate(4.0 - (this.currentWeek * 4) / (90 * 52))
      this.blip.play()
    }

    if (!this.passedAge && this.currentWeek > this.props.userAge * 52) {
      this.thud.play()
      this.passedAge = true
    }

    if (
      !this.passedRetirement &&
      this.currentWeek >
        Math.max(this.props.retirementAge, this.props.userAge) * 52
    ) {
      this.thud.play()
      this.passedRetirement = true
    }

    if (this.currentWeek > this.props.lifeExpectancy * 52) {
      const flatlineVolume =
        0.3 -
        this.currentWeek.map(this.props.lifeExpectancy * 52, 90 * 52, 0, 0.3)
      this.flatline.volume(flatlineVolume)

      if (!this.passedDeath) {
        this.flatline.play()
        this.thud.play()
        this.passedDeath = true
      }
    } else {
      this.flatline.stop()
      this.passedDeath = false
    }

    this.props.setCurrentWeek(this.currentWeek)
  }

  setSizes = () => {
    this.MAX_WIDTH = window.innerWidth.map(350, 2400, 1200, 2400)
    this.WEEK_SIZE = window.innerWidth.map(350, 2400, 8, 30)
    this.WEEK_SPACING = window.innerWidth.map(350, 2400, 2, 10)

    this.WEEK_OUTER_SIZE = this.WEEK_SIZE + this.WEEK_SPACING

    this.graphWidth = Math.min(this.MAX_WIDTH, window.innerWidth - 60)
    this.two.width = this.graphWidth
  }

  handleResize = () => {
    this.setSizes()
    const initialX =
      (this.graphWidth % this.WEEK_OUTER_SIZE) / 2 + this.WEEK_OUTER_SIZE * 2
    let xAxis = initialX
    let yAxis = this.WEEK_OUTER_SIZE / 2

    this.weeks.forEach(week => {
      week.translation.x = xAxis
      week.translation.y = yAxis
      week.width = this.WEEK_SIZE
      week.height = this.WEEK_SIZE

      xAxis += this.WEEK_OUTER_SIZE

      if (xAxis > this.graphWidth - this.WEEK_OUTER_SIZE * 2) {
        xAxis = initialX
        yAxis += this.WEEK_OUTER_SIZE
      }
    })

    const canvasHeight = this.weeks[
      this.weeks.length - 1
    ].getBoundingClientRect().top
    this.two.height = canvasHeight + this.WEEK_OUTER_SIZE / 2

    this.loadWeeksIn()
    this.props.repositionTimeBar()
  }

  componentDidMount = () => {
    /* SOUND FXs */
    this.blip = new Howl({
      src: [`/sounds/blip.mp3`],
      volume: 0.5,
    })

    this.thud = new Howl({
      src: [`/sounds/thud.mp3`],
      volume: 1,
    })

    this.flatline = new Howl({
      src: [`/sounds/flatline.mp3`],
      loop: true,
      volume: 0.5,
    })

    this.two = new Two({
      width: this.graphWidth,
      height: 3600,
      type: Two.Types.canvas,
    }).appendTo(this.stage)

    this.setSizes()

    this.two
      .bind(`update`, function() {
        // nothing
      })
      .play()

    this.setUpScene()
  }

  componentDidUpdate = prevProps => {
    if (
      this.state.introPlayed &&
      prevProps.currentScroll !== this.props.currentScroll
    ) {
      this.reFlowWeeks()
    }
  }

  render() {
    return (
      <GridWrapper>
        <TimeScale />
        <WeeksGrid ref={c => (this.stage = c)} />

        <IntroScene style={{ maxWidth: this.state.introSceneWidth + `px` }}>
          <IntroSquare style={this.state.introSquareStyles} />
          <IntroText className="styled-h2">
            This is a week in{` `}
            {this.props.userName ? this.props.userName + `'s` : `your`} life
          </IntroText>
        </IntroScene>
      </GridWrapper>
    )
  }
}

TimeGrid.propTypes = {
  currentScroll: PropTypes.number,
  setCurrentWeek: PropTypes.func,
  repositionTimeBar: PropTypes.func,
  userName: PropTypes.text,
  userAge: PropTypes.number,
  lifeExpectancy: PropTypes.number,
  retirementAge: PropTypes.number,
}

export default TimeGrid
