import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import Years from "../../assets/images/years.inline.svg"
import Months from "../../assets/images/months.inline.svg"
import Weeks from "../../assets/images/weeks.inline.svg"
import Days from "../../assets/images/days.inline.svg"

const Stat = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${props => props.theme.colorGreyLightest};
  margin: 0 ${rem(10)};
  border-radius: ${rem(4)};
  min-height: ${rem(300)};
  position: relative;
  overflow: hidden;
  transition: opacity 0.8s ease-out, transform 0.8s ease-in;
  opacity: ${props => (props.loaded ? 1 : 0)};
  transform: ${props =>
    props.loaded ? `translateY(0px)` : `translateY(10px)`};
  flex: 1;

  &:nth-child(1) {
    transition-delay: 0.2s;
  }
  &:nth-child(2) {
    transition-delay: 0.4s;
  }
  &:nth-child(3) {
    transition-delay: 0.6s;
  }
  &:nth-child(4) {
    transition-delay: 0.8s;
  }

  @media ${props => props.theme.xxxxlargeDown} {
    flex: auto;
    width: calc(50% - ${rem(10)});
    margin: 0 ${rem(5)};
    margin-bottom: ${rem(10)};
  }

  @media ${props => props.theme.mediumDown} {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    min-height: ${rem(200)};
  }

  &::before {
    content: "";
    display: none;
    background: ${props => props.theme.colorGreyLighter};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${rem(2)};
  }

  h1 {
    color: ${props => props.theme.colorGrey};
    display: flex;
    flex-direction: row;
    align-items: top;
    font-size: ${rem(24)};
    font-weight: 600;
    padding: ${rem(20)} ${rem(25)} 0 ${rem(25)};

    @media ${props => props.theme.largeDown} {
      font-size: ${rem(20)};
    }

    @media ${props => props.theme.mediumDown} {
      font-size: ${rem(18)};
    }
  }

  p {
    font-size: ${rem(72)};
    font-weight: 300;
    color: ${props => props.theme.colorBlueDark};
    font-weight: 300;
    padding: 0 ${rem(25)};

    @media ${props => props.theme.xxlargeDown} {
      font-size: ${rem(64)};
    }

    @media ${props => props.theme.largeDown} {
      font-size: ${rem(48)};
    }

    @media ${props => props.theme.mediumDown} {
      font-size: ${rem(36)};
    }

    @media ${props => props.theme.smallDown} {
      font-size: ${rem(36)};
      font-weight: 400;
    }
  }
`

const ScaleSelector = styled.ul`
  list-style: none;
  display: flex;
  margin-top: ${rem(15)};
  border-top: 1px solid ${props => props.theme.colorGreyLighter};
  cursor: pointer;

  li {
    flex: 1;
    color: ${props => props.theme.colorGreyLight};
    border-top: 2px solid transparent;
    font-size: ${rem(14)};
    padding: ${rem(15)} 0;
    margin-top: -1px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: ${props => props.theme.colorGreyLight};
      margin-right: ${rem(8)};
    }
  }

  li:hover,
  &.--years li:nth-child(1),
  &.--months li:nth-child(2),
  &.--weeks li:nth-child(3),
  &.--days li:nth-child(4) {
    color: ${props => props.theme.colorBlue};
    border-top-color: ${props => props.theme.colorBlue};

    svg {
      fill: currentColor;
    }
  }
`

class InteractiveCounter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      number: props.number,
      loaded: false,
      scale: props.scale,
    }
  }

  setNewNumber = scale => {
    switch (scale) {
      case `months`:
        return Math.round(this.props.number * 12)
      case `weeks`:
        return Math.round(this.props.number * 52)
      case `days`:
        return Math.round(this.props.number * 365)
      default:
        return this.props.number
    }
  }

  updateCounter = newScale => {
    this.setState({
      number: this.setNewNumber(newScale),
      scale: newScale,
    })
  }

  componentDidMount = () => {
    this.setState({
      number: this.setNewNumber(this.state.scale),
      loaded: true,
    })
  }

  render() {
    return (
      <Stat loaded={this.props.loaded}>
        <h1>{this.props.title}</h1>

        <p>
          {this.state.number < 0
            ? `None`
            : this.state.number + ` ` + this.state.scale}
        </p>

        <ScaleSelector className={`--` + this.state.scale}>
          <li onMouseEnter={() => this.updateCounter(`years`)}>
            <Years />
            Years
          </li>

          <li onMouseEnter={() => this.updateCounter(`months`)}>
            <Months />
            Months
          </li>

          <li onMouseEnter={() => this.updateCounter(`weeks`)}>
            <Weeks />
            Weeks
          </li>

          <li onMouseEnter={() => this.updateCounter(`days`)}>
            <Days />
            Days
          </li>
        </ScaleSelector>
      </Stat>
    )
  }
}

InteractiveCounter.propTypes = {
  number: PropTypes.number,
  title: PropTypes.text,
  scale: PropTypes.text,
  loaded: PropTypes.bool,
}

export default InteractiveCounter
