import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import times from "lodash/times"

const ScaleWrapper = styled.ul`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  li {
    position: relative;
    width: ${rem(15)};
    height: ${rem(1)};
    color: ${props => props.theme.colorGrey};
    background: ${props => props.theme.colorGreyLight};

    @media ${props => props.theme.mediumDown} {
      width: ${rem(8)};
    }

    span {
      display: none;
      font-size: ${rem(12)};
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(150%, -50%);
    }

    &:nth-child(10n + 1) {
      width: ${rem(30)};

      @media ${props => props.theme.mediumDown} {
        width: ${rem(15)};
      }

      span {
        display: block;
      }
    }
  }
`

class TimeScale extends React.Component {
  render() {
    return (
      <ScaleWrapper>
        {times(91, i => (
          <li key={i}>
            <span>{i}</span>
          </li>
        ))}
      </ScaleWrapper>
    )
  }
}

export default TimeScale
